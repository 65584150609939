<template>
  <div class="flex justify-center items-center">
    <svg
      width="48"
      height="48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="animate-spin"
    >
      <circle cx="24" cy="24" r="17" stroke="#D3D9DF" stroke-width="2" />
      <path
        d="M41 24c0-9.389-7.611-17-17-17"
        stroke="#1DA1F2"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="24" cy="24" r="17" stroke="#D3D9DF" stroke-width="2" />
      <path
        d="M41 24c0-9.389-7.611-17-17-17"
        stroke="#1DA1F2"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
